import {GenIcon} from "react-icons/lib";

export function FaGitVerse(props) {
    return GenIcon({
        "tag": "svg",
        "attr": {"viewBox": "0 0 160 175"},
        "child": [
            {
                "tag": "path",
                "attr": {"d": "M146.3 5.5C141.4 8 136 13 129.4 21.4l-3.1 4-5.9-2.2C111.6 20 102.3 18 95.3 18h-6.1l-3.6-5c-4.1-5.8-8.8-9-13-9-4.6 0-10.1 6.2-11.7 13.4-1.2 5.5-1.5 5.9-5.6 7.7-12.7 5.4-26.9 17.6-34.7 29.7C11.6 68.6 8 80.8 8 97.3c0 14.1 2 22.8 8.2 35.7 4.6 9.5 6.1 11.5 15.2 20.5 8.3 8.3 11.7 10.9 19.2 14.8 14.8 7.5 22.1 9.2 40.4 9.1 14.2 0 16.2-.2 24-2.8 33.4-10.9 55.4-38.3 57.7-71.8.6-9.1-.8-20.9-3.3-28.1-1.2-3.5-1.3-6.8-.3-21.2 2.2-32.8-2.1-48.7-13.8-50.1-2.8-.4-5.1.1-9 2.1zM159 9.7c4.8 4.4 6.2 11 6.2 30.3 0 9.1-.6 20.5-1.3 25.5-.7 5.3-1.3 20-1.3 35.5-.1 30.1.2 28.9-9.6 42.2-9.3 12.6-26.9 24.2-42.9 28.3-14.6 3.7-32.4 2.9-46.5-2.2-11.7-4.2-11.6-4-5.5-7.6 3-1.6 9.9-6.4 15.4-10.6 16.3-12.4 26.8-16.2 46.6-16.4 6.5-.1 11.5-.5 11.3-.8-.2-.3-3.2-1.2-6.6-2-7.5-1.6-18.2-1.7-41.3-.5-15.2.8-17.7.7-22.5-.9-8.7-2.8-20.1-9.1-25.5-14l-5-4.5 4 1.6c2.2.9 8.5 3.3 14 5.2 8.9 3.2 11.1 3.6 20.1 3.6 9.6.1 10.3 0 17.8-3.7 4.3-2.2 7.6-4 7.4-4.2-.2-.2-2.8.3-5.8 1.2-7.5 2-23.2 1.2-32.5-1.7-11.3-3.5-22.1-8.9-23.4-11.7-2-4.3-1.2-5.9 4.3-10.2 5-3.9 6.5-6 8.1-11.7.8-3.1-1.9-5.4-6.3-5.4-2 0-5.5-.6-7.7-1.2-4-1.2-4-1.2 5-1.9 5-.4 14-.7 20-.8 12.7-.1 10.7.8 27.2-12.8C95.3 48 99.9 46 111 46h6.6l7.8-11.4c9.6-14.2 17.8-23.3 23.1-25.7 5.4-2.5 6.9-2.4 10.5.8zm-81.5.5c3.7 2.9 8.9 11.2 13.1 20.9 1.8 4.5 3.4 8.4 3.4 8.9 0 .4-2.7 1-6 1.4-7.4.9-12.1 2.7-18.5 7.5-8.5 6.3-7.9 6.9-7.1-7 1.2-20.9 3.1-29.3 7.5-32.6 2.4-1.8 4.5-1.6 7.6.9zm11.3 36.7c-.1.5-5.9 5.2-12.7 10.5C64.2 66.6 63.6 67 58.7 67c-2.9 0-4.7-.4-4.2-.9C58 63 77.1 49.2 80 47.8c3.6-1.9 9.4-2.4 8.8-.9zM26 99.2c0 2.7-.2 4.8-.6 4.8-1.1 0-3.4-7.1-3.4-10.7v-3.8l2 2.5c1.3 1.7 2 4.1 2 7.2z"}
            },
            {
                "tag": "path",
                "attr": {"d": "M142.9 33.7c-2.4 2.6-5.9 7.1-7.7 10l-3.3 5.2 7.8 7.5c4.3 4.1 8.1 7.2 8.5 6.8 1.5-1.5 4.9-21.8 4.6-27.6-.3-5.7-.5-6.1-2.9-6.4-1.9-.2-3.6.8-7 4.5zM89.5 69c-2.2 4.3-1.9 6.8 1.4 10.1 4.8 4.8 12 3.7 14.6-2.3 1.8-4.5.9-6.4-4.5-8.7-6.9-3-9.6-2.8-11.5.9z"}
            }
        ]
    })(props);
}
